import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types"


import { Loader } from './Loader';

const Story = ({ url }) => {
    const [isLoading, setLoading] = useState(true);
    return (
        <StoryDiv>
            <OverlayHide isLoading={isLoading} />

            {isLoading && <LoaderStyled size={40} />}
            <IframeStyled
                src={url}
                frameBorder="0"
                onLoad={() => {
                    setLoading(false);
                }}
            />
        </StoryDiv>
    );
};

Story.propTypes = {
  url: PropTypes.string.isRequired,
}

export default Story;

const IframeStyled = styled.iframe`
    position: fixed;
    background: #000;
    border: none;
    top: 0; right: 0;
    bottom: 0; left: 0;
    width: 100%;
    height: 100%;
`;
const LoaderStyled = styled(Loader)`
    position: absolute;
    top: calc(50% - 20px);
    animation: fadeInFullScreen 1s linear forwards;
    @keyframes fadeInFullScreen {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    left: calc(50% - 20px);
    transition: opacity 2s, visibility 2s;
`;
const StoryDiv = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    animation: fadeInFullScreen 0.5s linear forwards;

    @keyframes fadeInFullScreen {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
const OverlayHide = styled.div`
    position: fixed;
    background-color: #202125;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    opacity: ${props => (props.isLoading ? '1' : '0')};
    visibility: ${props => (props.isLoading ? 'visible' : 'hidden')};
    transition: opacity 1s, visibility 1s;
`;


