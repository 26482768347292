import React from 'react';
import styled from 'styled-components';
import { ModalRoutingContext, Link } from 'gatsby-plugin-modal-routing';
import { Close } from '@material-ui/icons';
import Story from '../components/Story';

const buildStoryUrl = pathname => {
    const [, slug] = pathname.split('/story/');
    return `/${slug}/`;
};
const ModalExamplePage = ({ location }) => (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
            <React.Fragment>
                <Link to={modal ? closeTo : '/'}>
                    <CloseStorySpan>
                        <Close style={{ height: '40px', width: '40px' }} />
                    </CloseStorySpan>
                </Link>
                <Story url={modal ? location.state.storyUrl : buildStoryUrl(location.pathname)} closeStory={closeTo} />
            </React.Fragment>
        )}
    </ModalRoutingContext.Consumer>
);
const CloseStorySpan = styled.span`
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 1000;
    font-size: 20px;
    cursor: pointer;
    color: white;
`;
export default ModalExamplePage;
